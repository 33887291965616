<template>
  <div class="dashboard-content">
    <div class="row gx-5 gy-5">
      <div class="col"
           v-for="item in items"
           :key="item.link"
      >
        <div class="p-3 border bg-light rounded">
          <router-link :to="item.link" class="nav-link">
            <font-awesome-icon :icon="['fas', item.icon]" />
            <span>{{ item.text }}</span>
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "DashBoard",
  data() {
    return {
      items: [
        {
          link: "/new-billable/236/12/",
          icon: "file-signature",
          text: "Create a default billable for GEA"
        },
        {
          link: "/new-billable/223/8/",
          icon: "file-signature",
          text: "Create a default billable for NOAH"
        },
        {
          link: "/new-billable",
          icon: "file-signature",
          text: "Create a billable"
        },
        {
          link: "/new-invoice",
          icon: "file-invoice-dollar",
          text: "Create an invoice"
        },
        {
          link: "/new-customer",
          icon: "user-tie",
          text: "Create a customer"
        }
      ]
    }
  }
};
</script>

<style>
  .dashboard-content {
    margin: 40px 40px 0 40px;
  }
  .dashboard-content .nav-link {
    text-align: center;
  }
  .dashboard-content .nav-link span {
    clear:both;
    display:block;
    margin-top: 20px;
  }

  .dashboard-content .nav-link svg.svg-inline--fa {
    font-size: 5em;
    display: inline;
  }
</style>
